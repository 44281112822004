import React from 'react';
import './ScanInfo.scss'

export default function ScanInfo() {

    return (
        <div className="ScanInfo">
            <div className="d-flex flex-column align-items-center">
                <h1>MaintenanceChecker</h1>
                <h5 id="info-text">Bitte den RFID-Chip der ortsveränderlichen Komponente scannen, um zu Prüfen, ob eine Wartung fällig ist</h5>
                <img id="scan-logo" src="../assets/scan_logo.png" alt="Conexo Scan Logo"/>
            </div>
        </div>
    );
}
