import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ErrorPopup from '../errorpopup/ErrorPopup';
import Login from '../login/Login';
import ScanPage from '../scan/ScanPage';
import ScannedComponent from '../scannedcomponent/ScannedComponent';
import User from '../User';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import './App.scss';


export default function App() {

  let user = User.Instance
  const history = useHistory()
  let [isAuthenticated, setAuthentication] = useState(user.isTokenValid())
  let [showSessionExpired, setShowSessionExpired] = useState(false)

  let interval = null

  useEffect(() => {

    interval = setInterval(() => {

      let tokenValid = user.isTokenValid()
      if (!tokenValid && isAuthenticated) {
        setShowSessionExpired(true)
      }
    }, 10000);

    return () => {
      clearInterval(interval)
    };
  })


  const callbackLogin = () => {
    setAuthentication(user.isTokenValid())
  }

  const closeSessionExpiredPopUp = () => {
    setShowSessionExpired(false)
    user.logout()
    setAuthentication(false)
    history.push("/")
  }


  function NoMatch() {
    return (
      <div>
        <h3>
          Requested route not found :(
        </h3>
      </div>
    );
  }

  return (
    <div className="App">
      <ErrorPopup
        title="Sitzung abgelaufen"
        message="Bitte melden sie sich erneut an."
        show={showSessionExpired}
        closePopup={closeSessionExpiredPopUp} />
      <Router>
        <Switch>
          <Route exact path="/">
            {
              isAuthenticated ? <Redirect to="/scan" /> : <Redirect to="/login" />
            }
          </Route>
          <Route path="/login">
            {
              isAuthenticated ? <Redirect to="/scan" /> : <Login loginCallback={callbackLogin} />
            }
          </Route>
          <ProtectedRoute
            path="/scan"
            component={ScanPage}
            isAuthenticated={isAuthenticated} />
          <ProtectedRoute
            path="/scannedcomponent"
            component={ScannedComponent}
            isAuthenticated={isAuthenticated} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
