import { Button } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import SettingsMaintenanceCylceInMonthKey from './maintenancecycleinmonthkey/SettingsMaintenanceCylceInMonthKey';
import SettingsMaintenancePeriod from './maintenanceperiod/SettingsMaintenancePeriod';
import SettingsMaintenanceTemplateToBeUsedKey from './maintenancetemplatetobeusedkey/SettingsMaintenanceTemplateToBeUsedKey';
import SettingsNavigateBack from './navigateback/SettingsNavigateBack';
import './Settings.scss';
import { SettingsHandler } from './SettingsHandler';

export interface SettingsProps {
    show: boolean
    closeSettings: () => void
}


export default function Settings(props) {

    const settings = SettingsHandler.Instance

    const onSaveSettings = () => {
        if (settings.settingsChanged()) {
            settings.sendSettingsToServer().then(res => {
                props.closeSettings()
            })
        }
        else {
            props.closeSettings()
        }

    }

    const onCancel = () => {
        settings.rollBackSettings()
        props.closeSettings()

    }

    return (
        <>
            <Modal
                className="Settings"
                show={props.show}
                onHide={props.closeSettings}
                backdrop="static">
                <Modal.Header>
                    <Modal.Title>Einstellungen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SettingsNavigateBack />
                    <SettingsMaintenanceCylceInMonthKey />
                    <SettingsMaintenanceTemplateToBeUsedKey />
                    <SettingsMaintenancePeriod />
                </Modal.Body>
                <Modal.Footer>
                    <Button id="cancel-button" variant="contained" className="mr-3" onClick={onCancel}>
                        <strong>Abbrechen</strong>
                    </Button>
                    <Button id="save-button" variant="contained" onClick={onSaveSettings}>
                        <strong>Speichern</strong>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
