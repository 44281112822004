import React, { useEffect, useState } from 'react'
import { IAttribute } from '../../models/IOutputData';
import '../WebViewStyle.scss'

export interface ComponentComponentProps {
    caption: string,
    attributeList: IAttribute[]
}


export default function ComponentComponent(props: ComponentComponentProps) {
    const [showComponentComponentAttributes, setShowComponentProductAttributes] = useState(false)
    useEffect(() => {

        let showProductAttributesEmpty = !(props.attributeList.length === 0)
        setShowComponentProductAttributes(showProductAttributesEmpty)

        return () => {

        };

    }, [props])


    return (
        <div className="ComponentProduct">
            {
                showComponentComponentAttributes ?
                    <div>
                        <details open>
                            <summary>{props.caption}</summary>
                            <br />
                            <table className="table">
                                <tbody>
                                    {
                                        props.attributeList.map((attribute: IAttribute, index) => {
                                            if (attribute.isLink) {
                                                return (
                                                    <tr key={index}>
                                                        <td><b>{attribute.title}</b></td>
                                                        <td><a href={attribute.value} target="_blank" rel="noreferrer">Link öffnen</a></td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return (
                                                    <tr key={index}>
                                                        <td><b>{attribute.title}</b></td>
                                                        <td>{attribute.value}</td>
                                                    </tr>
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </details>
                    </div>
                    : null
            }

        </div>
    );

}
