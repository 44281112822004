import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SettingsHandler } from '../SettingsHandler';
import './SettingsMaintenanceCylceInMonthKey.scss'


export default function SettingsMaintenanceCylceInMonthKey(props) {

    const settings = SettingsHandler.Instance
    let config = settings.getSettings()

    let [maintenanceCycleKey, setMaintenanceCycleKey] = useState(config.maintenanceCycleKey)

    const onChangeValue = (value) => {
        setMaintenanceCycleKey(value)
        config.maintenanceCycleKey = value
    }


    return (
        <div className="SettingsMaintenanceCylceInMonthKey">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>Alternativer Wert für
                        <strong>&nbsp;Maintenance-Cycle-in-Month</strong>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    type="text"
                    value={maintenanceCycleKey} onChange={e => onChangeValue(e.target.value)} />
            </InputGroup>
        </div>
    );
}
