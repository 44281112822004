import React from 'react'
import './MaintenanceHeader.scss'

export interface MaintenanceHeaderProps {
    title: string,
    color: string
}

export default function MaintenanceHeader(props: MaintenanceHeaderProps) {

    let style = { "--header-color": props.color } as React.CSSProperties;

    return (
        <div className="MaintenanceHeader">
            <div style={style} className="header d-flex flex-column align-items-center">
                <h1>{props.title}</h1>
            </div>
        </div>
    );

}
