export interface IOutputData {
    articleDescriptionS: string,
    articleDescriptionL: string,
    icon: any,
    caption_general: string,
    caption_manufacturer_productattributes: string,
    caption_manufacturer_componentattributes: string,
    caption_documents: string,
    generalAttributesList: IKeyValue[],
    productAttributesList: IAttribute[],
    manufacturerAttributesList: IAttribute[],

    maintenance: Maintenance
}

export interface IKeyValue {
    title: string,
    value: string,
    iconUsage: boolean,
    isProductInfo: boolean,
    isLink: boolean
}

export interface IGeneralAttributes {
    title: string,
    value: string,
    iconUsage: boolean,
    isProductInfo: boolean,
    isLink: boolean
}

export interface IAttribute {
    title: string
    value: string
    prioInOverview: number,
    isLink: boolean
}

export interface Maintenance {
    state: MaintenanceState
    error: MaintenanceCheckerTypes
}

export enum MaintenanceState {
    REQUIRED = "REQUIRED",
    ALREADY_CREATED = "ALREADY_CREATED",
    NOT_REQUIRED = "NOT_REQUIRED",
    NOT_REQUIRED_RECENTLY_INSTALLED = "NOT_REQUIRED_RECENTLY_INSTALLED"
}

export enum MaintenanceCheckerTypes {
    COMPONENT_NOT_INSTALLED = "COMPONENT_NOT_INSTALLED",
    COMPONENT_MUTLIPLE_INSTALLED = "COMPONENT_MUTLIPLE_INSTALLED",
    COULD_NOT_DETERMINE_TAGGEDLOCATION = "COULD_NOT_DETERMINE_TAGGEDLOCATION",
    TAGGEDLOCATION_HAS_NO_LOCATION = "TAGGEDLOCATION_HAS_NO_LOCATION",
    MAINTENANCE_TEMPLATE_NOT_CREATED = "MAINTENANCE_TEMPLATE_NOT_CREATED",
    MAINTENANCE_TEMPLATE_AVAILABLE_MULTIPLE_TIMES = "MAINTENANCE_TEMPLATE_AVAILABLE_MULTIPLE_TIMES",
    MAINTENANCE_TEMPLATE_NOT_PROPERLY_CARED_FOR = "MAINTENANCE_TEMPLATE_NOT_PROPERLY_CARED_FOR",
    ATTRIBUTE_MAINTENANCE_CYCLE_NOT_CREATED = "ATTRIBUTE_MAINTENANCE_CYCLE_NOT_CREATED",
    ATTRIBUTE_MAINTENANCE_CYCLE_AVAILABLE_MULTIPLE_TIMES = "ATTRIBUTE_MAINTENANCE_CYCLE_AVAILABLE_MULTIPLE_TIMES",
    ATTRIBUTE_MAINTENANCE_CYCLE_IS_NOT_A_NUMBER = "ATTRIBUTE_MAINTENANCE_CYCLE_IS_NOT_A_NUMBER",
    MAINTENANCE_ALREADY_CREATED = "MAINTENANCE_ALREADY_CREATED",
    NO_MAINTENANCE_REQUIRED_RECENTLY_INSTALLED = "NO_MAINTENANCE_REQUIRED_RECENTLY_INSTALLED",
    NO_MAINTENANCE_REQUIRED = "NO_MAINTENANCE_REQUIRED",
    MAINTENANCE_REQUIRED = "MAINTENANCE_REQUIRED"
}


export enum ComponentDecoderError {
    MORE_THAN_ONE_INSTALLATION = "MORE_THAN_ONE_INSTALLATION",
    COMPONENT_NOT_FOUND = "COMPONENT_NOT_FOUND",
    UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
    UNAUTHORIZED = "UNAUTHORIZED"
}

//Nur relevant für Endperiod Config
export enum IDateTypes {
    DAYS = "DAYS",
    WEEKS = "WEEKS",
    MONTHS = "MONTHS",
}

export interface ITime {
    value: number,
    type: IDateTypes,
}

export interface IConfig {
    maintenanceCycleKey: string,
    maintenanceTemplateKey: string,
    returnTimeAfterScan: number,
    endPeriod: ITime
}
