import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { IDateTypes } from '../../models/IOutputData';
import { SettingsHandler } from '../SettingsHandler';
import './SettingsMaintenancePeriod.scss';

export interface SettingsProps {
    show: boolean
    closeSettings: () => void
}

export default function SettingsMaintenancePeriod(props) {

    const settings = SettingsHandler.Instance
    let config = settings.getSettings()

    let [endPeriodValue, setEndPeriodValue] = useState(config.endPeriod.value)
    let [endPeriodType, setEndPeriodType] = useState(config.endPeriod.type)

    const onChangePeriodType = (value) => {
        setEndPeriodType(value)
        config.endPeriod.type = value
    }

    const onChangePeriodValue = (value) => {
        setEndPeriodValue(value)
        config.endPeriod.value = value
    }

    return (
        <div className="SettingsMaintenancePeriod">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>Zeitraum für Bearbeitung der Wartung</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    id="maintenance-periode-value"
                    type="number"
                    min="1"
                    value={endPeriodValue}
                    onChange={e => { onChangePeriodValue(e.target.value) }} />
                <Form.Control
                    id="maintenance-periode-type"
                    as="select"
                    defaultValue={endPeriodType}
                    onChange={e => { onChangePeriodType(e.target.value) }}>
                    <option>{IDateTypes.DAYS}</option>
                    <option>{IDateTypes.WEEKS}</option>
                    <option>{IDateTypes.MONTHS}</option>
                </Form.Control>
            </InputGroup>
        </div>
    );
}
