declare global {
    interface StringConstructor {
      isEmpty(s:string): boolean;
    }
  }
  
  String.isEmpty = (s:string) => {
    return s.length === 0;
  };

  
  export {}