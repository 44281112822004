import { IConfig } from "../models/IOutputData"
import User from "../User"
import Utils from "../utils/Utils"

export class SettingsHandler {

    private static instance: SettingsHandler = null
    private settings: IConfig = null

    private readonly settingsSessionStorageKey = "settings"

    private constructor() {

    }

    static get Instance(): SettingsHandler {
        if (SettingsHandler.instance == null)
            SettingsHandler.instance = new SettingsHandler()
        return SettingsHandler.instance
    }


    initializeSettings(_settings: IConfig) {
        this.settings = _settings

        Utils.setSessionStorageItem(
            this.settingsSessionStorageKey,
            JSON.stringify(this.settings)
        )

    }

    private getSettingsFromStorage(): IConfig {
        return JSON.parse(Utils.getSessionStorageItem(this.settingsSessionStorageKey)) as IConfig
    }

    getSettings() {
        if(this.settings == null){
            this.settings = this.getSettingsFromStorage()
        }
        return this.settings
    }

    rollBackSettings() {
        this.settings = this.getSettingsFromStorage()
    }

    async sendSettingsToServer(): Promise<boolean> {
        return fetch('/api/updatesettings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': User.Instance.session.access_token
            },
            body: JSON.stringify({
                maintenanceCycleKey: this.settings.maintenanceCycleKey,
                maintenanceTemplateKey: this.settings.maintenanceTemplateKey,
                returnTimeAfterScan: parseInt(this.settings.returnTimeAfterScan.toString()),
                endPeriod: {
                    value: parseInt(this.settings.endPeriod.value.toString()),
                    type: this.settings.endPeriod.type
                }
            })
        }).then(async response => {
            let result = await response.json()
            if (response.status >= 400 && response.status < 600) {
                throw (result)
            }
            return result
        }).then(result => {
            this.persistSettings()
            return true
        })
            .catch(err => {
                console.log("Error: " + err)
                return false
            })
    }

    settingsChanged(): boolean {
        return this.settings !== this.getSettingsFromStorage()
    }

    persistSettings() {
        Utils.setSessionStorageItem(this.settingsSessionStorageKey, JSON.stringify(this.settings))
    }

}