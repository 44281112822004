import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface IconButtonWithOverlayProps {
    overlayText: string,
    icon: IconDefinition,
    onClickFunction: () => void
}

export default function IconButtonWithOverlay(props: IconButtonWithOverlayProps) {

    return (
        <div className="IconButtonWithOverlay">
            <OverlayTrigger
                key="left"
                placement="left"
                overlay={
                    <Tooltip id={`tooltip-left`}>
                        <strong>{props.overlayText}</strong>
                    </Tooltip>
                }>
                <Button id="settings-button" variant="light" size="lg" onClick={props.onClickFunction}>
                    <FontAwesomeIcon id="settings-icon" icon={props.icon} />
                </Button>
            </OverlayTrigger>
        </div>
    );
}
