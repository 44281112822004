import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SettingsHandler } from '../SettingsHandler';
import './SettingsNavigateBack.scss'

export default function SettingsNavigateBack() {

    const settings = SettingsHandler.Instance
    let config = settings.getSettings()
    let [returnTimeAfterScan,setReturnTimeAfterScan] = useState(config.returnTimeAfterScan)

    const onChangeTimeToNavigateBack = (value) => {
        setReturnTimeAfterScan(value)
        config.returnTimeAfterScan = value
    }

    return (
        <div className="SettingsNavigateBack" >
            <InputGroup >
                <InputGroup.Prepend>
                    <InputGroup.Text>Nach</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    id="time-to-navigate-back"
                    as="select"
                    defaultValue={returnTimeAfterScan}
                    onChange={e=> onChangeTimeToNavigateBack(e.target.value)}>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                </Form.Control>
                <InputGroup.Append>
                    <InputGroup.Text>Sekunden zurück navigieren</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </div>
    );
}
