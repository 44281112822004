export enum EPCErrors {
    TidStartWithInvalidSign = "TidStartWithInvalidSign",
    TidIsTooShort = "TidIsTooShort",
    TidNoDelimiter = "TidNoDelimiter",
    TidWrongLength = "TidWrongLength",
    EpNoEpc = "EpNoEpc",
    TidNoTid = "TidNoTid"
}

export default class EPCInterpreter {

    handleScanData(text: string): string {

        try {
            let data = this.checkTidFormat(text)
            let tidEpcPair: [string, string] = this.checkTidLength(data)

            const tid = tidEpcPair[0]
            const epc = tidEpcPair[1]

            if (String.isEmpty(epc)) {
                throw EPCErrors.EpNoEpc
            }

            if (String.isEmpty(tid)) {
                throw EPCErrors.TidNoTid
            }

            return tid
        } catch (error) {
            throw error
        }

    }

    private checkTidFormat(text: string): string {
        if (text.startsWith("E2")) {
            return text
        }
        else {
            let index = text.indexOf("E2")
            if (index !== -1)
                return text.substring(index)
            else
                throw EPCErrors.TidStartWithInvalidSign.valueOf()
        }
    }

    private checkTidLength(text: string): [string, string] {
        //ASCII #
        let index = text.indexOf("#")

        //ASCII §
        if (index === -1) {
            index = text.indexOf("§")
        }

        //ASCII $
        if (index === -1) {
            index = text.indexOf("$")
        }

        //ASCII £
        if (index === -1) {
            index = text.indexOf("£")
        }

        if (index === -1) {
            throw EPCErrors.TidNoDelimiter.valueOf()
        }

        if (index !== 24) {
            throw EPCErrors.TidWrongLength.valueOf()
        }

        let result: [string, string];

        const tid = text.substr(0, index)
        const epc = text.substring(index)

        result = [tid, epc]

        return result

    }

}
