import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../WebViewStyle.scss'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IKeyValue } from '../../models/IOutputData';


export interface ComponentGeneralProps {
    caption: string,
    attributeList: IKeyValue[]
}



export default function ComponentGeneral(props: ComponentGeneralProps) {

    return (
        <div className="ComponentGeneral">
            <details open>
                <summary>{props.caption}</summary>
                <br />
                <table className="table">
                    <tbody>
                        {
                            props.attributeList.map((attribute: IKeyValue, index) => {
                                if (attribute.isProductInfo) {
                                    return (
                                        <tr key={index}>
                                            <td colSpan={2}><b>{attribute.title}</b><br />{attribute.value}</td>
                                        </tr>
                                    )
                                }
                                else if (attribute.iconUsage) {
                                    return (
                                        <tr key={index}>
                                            <td><b>{attribute.title}</b></td>
                                            <td>{attribute.value} <FontAwesomeIcon icon={faCheckCircle} /></td>
                                        </tr>
                                    )
                                }
                                else if (attribute.isLink) {
                                    return (
                                        <tr key={index}>
                                            <td><b>{attribute.title}</b></td>
                                            <td><a href={attribute.value} target="_blank" rel="noreferrer">Link öffnen</a></td>
                                        </tr>
                                    )
                                }
                                else {
                                    return (
                                        <tr key={index}>
                                            <td><b>{attribute.title}</b></td>
                                            <td>{attribute.value}</td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </table>
            </details>
        </div>
    );

}
