import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import ErrorPopup from '../errorpopup/ErrorPopup';
import { ComponentDecoderError } from '../models/IOutputData';
import Settings from '../settings/Settings';
import User from '../User';
import EPCInterpreter, { EPCErrors } from '../utils/EPCInterpreter';
import IconButtonWithOverlay from '../utils/IconButtonWithOverlay';
import ScanInfo from './scaninfo/ScanInfo';
import './ScanPage.scss';


export default function ScanPage(props) {

    let user = User.Instance

    let [scannedText, setScannedText] = useState("")
    let [typedText, setTypedText] = useState("")
    let [viewSettings] = useState(user.canUserChangeSettings())

    let epcInterpreter = new EPCInterpreter()

    let [errorTitle, setErrorTitle] = useState("")
    let [errorMessage, setErrorMessage] = useState("")
    let [showError, setShowError] = useState(false)

    let [showSettings, setShowSettings] = useState(false)


    //Used to focus the input element on page start
    const textInput = useCallback(node => {
        if (node !== null) {
            node.focus();
        }
    }, [])

    const hideErrorPopup = () => {
        setShowError(false)
        window.location.reload()
    }

    const keyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            if (typedText.length !== 0) {
                interpreteScanData(typedText)
            }
            else {
                interpreteScanData(scannedText)
            }
        }
        else {
            let newScannedText = (scannedText += e.key)
            setScannedText(newScannedText)
        }
    }

    const showEpcError = (error: string) => {
        setErrorTitle("Fehler beim Scannen")
        switch (error) {
            case EPCErrors.TidStartWithInvalidSign:
                setErrorMessage("CONEXO-ID beginnt nicht mit 'E2', bitte erneut Scannen")
                break;
            case EPCErrors.TidIsTooShort:
                setErrorMessage("CONEXO-ID ist zu kurz, bitte erneut Scannen")
                break;
            case EPCErrors.TidNoDelimiter:
                setErrorMessage("TidNoDelimiter")
                break;
            case EPCErrors.TidWrongLength:
                setErrorMessage("TidWrongLength")
            break;
            case EPCErrors.TidNoTid:
                setErrorMessage("TidNoTid")
                break;
            case EPCErrors.EpNoEpc:
                setErrorMessage("EpNoEpc")
                break;
        }
        setShowError(true)
    }

    const showComponentError = (error: string) => {
        switch (error) {
            case ComponentDecoderError.COMPONENT_NOT_FOUND:
                setErrorTitle("Komponente nicht gefunden")
                setErrorMessage("Bitte Spielen Sie den Datensatz ein und verbauen Sie die Komponente an einem technischen Platz")
                setShowError(true)
                break;
            case ComponentDecoderError.MORE_THAN_ONE_INSTALLATION:
                setErrorTitle("Mehr als eine Installation")
                setErrorMessage("Der Maintenance Checker funktioniert nur bei Komponenten, die zu einem Zeitpunkt an einem technischen Platz verbaut sein könnnen. Probieren Sie, die Membrane zu scannen.")
                setShowError(true)
                break;
            case ComponentDecoderError.UNAUTHORIZED:
                user.logout()
                setErrorTitle("Session abgelaufen")
                setErrorMessage("Bitte melden sie sich erneut an.")
                setShowError(true)
                break;
            case ComponentDecoderError.UNEXPECTED_ERROR:
                setErrorTitle("Unerwarteter Fehler")
                setErrorMessage("Ups dies hätte nicht passieren dürfen. :(")
                setShowError(true)
                break;
            default:
                break;
        }

    }

    const interpreteScanData = (text: string) => {
        try {
            let tid = epcInterpreter.handleScanData(text)

            fetch('/api/checkmaintenance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': user.session.access_token
                },
                body: JSON.stringify({
                    username: user.session.username,
                    componentId: tid
                })
            }).then(async response => {
                let result = await response.json()

                return result
            }).then(result => {
                if ((result.error !== null) && (result.error !== undefined)) {
                    showComponentError(result.error)
                }
                else {
                    props.history.push({
                        pathname: "/scannedcomponent",
                        state: {
                            scannedcomponent: result
                        }
                    });
                }
            })
                .catch(err => {
                    console.log("Error: " + err)
                })
        } catch (error) {
            console.log("EPC Error: " + error)
            showEpcError(error)
        }
    }

    const onSendTIDClick = () => {
        interpreteScanData(typedText)
    }

    const openSettingsModal = () => {
        setShowSettings(true)
    }

    const closeSettingsModal = () => {
        setShowSettings(false)
    }

    return (
        <div className="Scan" >
            <Settings closeSettings={closeSettingsModal} show={showSettings} />
            <ErrorPopup title={errorTitle} message={errorMessage} show={showError} closePopup={hideErrorPopup} />
            <div id="scan-header">
                <img id="scan-header-company-logo" src="../assets/gsk-logo.png" alt="GSK Logo" />
                {
                    viewSettings ?
                        <IconButtonWithOverlay
                            overlayText="Einstellungen"
                            icon={faCog}
                            onClickFunction={openSettingsModal} /> : null
                }

                {/*<IconButtonWithOverlay overlayText="Logout" icon={faSignOutAlt} onClickFunction={logout} />*/}
            </div>
            <ScanInfo />
            <div id="scanInput" className="d-flex justify-content-center">
                <input type="text"
                    onKeyPress={keyPressed}
                    value={typedText}
                    onChange={e => setTypedText(e.target.value)}
                    tabIndex={0} ref={textInput} />
                <Button  variant="contained" type="button" onClick={onSendTIDClick} >
                    Absenden
                </Button>
            </div>

        </div>
    );
}
