import React from 'react'
import '../WebViewStyle.scss'

export interface ComponentHeadingProps {
    title: string,
    icon: string
}

export default function ComponentHeading(props: ComponentHeadingProps) {

    return (
        <div className="ComponentHeading">
            <div id="product-title">
                {props.title}
            </div>
            <br />
            <div id="product-icon-container">
                <img src={props.icon} alt="Komponenten Bild" />
            </div>
        </div>
    );

}
