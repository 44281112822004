import { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { SettingsHandler } from '../SettingsHandler';
import './SettingsMaintenanceTemplateToBeUsedKey.scss'

export default function SettingsMaintenanceTemplateToBeUsedKey(props) {

    const settings = SettingsHandler.Instance
    let config = settings.getSettings()

    let [maintenanceTemplateKey, setMaintenanceTemplateKey] = useState(config.maintenanceTemplateKey)

    const onChangeValue = (value) => {
        setMaintenanceTemplateKey(value)
        config.maintenanceTemplateKey = value
    }

    return (
        <div className="SettingsMaintenanceTemplateToBeUsedKey">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>Alternativer Wert für
                        <strong>&nbsp;Maintenance-Template-to-be-used</strong>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    id="maintenance-template-key"
                    type="text"
                    value={maintenanceTemplateKey}
                    onChange={e => onChangeValue(e.target.value)} />
            </InputGroup>
        </div>
    );
}
