import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { useHistory } from 'react-router'
import User from '../User'
import '../utils/string.extensions'
import './Login.scss'

export interface LoginProps {
    loginCallback: () => void
}

export default function Login(props: LoginProps) {

    const user = User.Instance
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loginFailed, setLoginFailed] = useState(false)
    const history = useHistory()


    function loginUser(event) {
        event.preventDefault()
        if (String.isEmpty(username) || String.isEmpty(password)) {
            return;
        }

        user.login(username, password).then(result => {
            if (result) {
                setLoginFailed(false)
                navigateNext()
            }
            else {
                setLoginFailed(true)
                setUsername("")
                setPassword("")
            }
        })
    }

    const navigateNext = () => {
        props.loginCallback()
        history.push("/scan")
    }

    return (
        <div className="Login">
            <div id="login-header" role="banner" className="d-flex flex-column align-items-center">
                <img id="logo" src="../assets/logo.png" alt="Conexo Logo" /> <h4>Maintenance Checker</h4>
            </div>
            <div id="login-form">
                <div className="d-flex flex-column align-items-center">
                    <Alert show={loginFailed} variant="danger" >
                        <FontAwesomeIcon icon={faExclamationCircle} /> Benutzername oder Passwort falsch. Bitte erneut versuchen.
                    </Alert>
                </div>
                    <Card id="login-card">
                        <CardHeader
                            title="Bitte anmelden" />
                        <CardContent>
                            <Form className="LoginForm" onSubmit={loginUser}>
                                <div id="input-username-wrapper">
                                    <TextField
                                        required
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                        type="text"
                                        label="Benutzername" />
                                </div>
                                <div id="input-password-wrapper">
                                    <TextField
                                        required
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        type="password"
                                        label="Passwort" />
                                </div>
                                <div id="signin-button-wrapper" className="text-center">
                                    <Button variant="contained" fullWidth type="submit">
                                        Anmelden
                                    </Button>
                                </div>
                            </Form>
                        </CardContent>
                    </Card>
            </div>
        </div>
    );
}
