import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import './index.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Router } from 'react-router';
import history from './history';


ReactDOM.render(
    <Router history={history}>
      <App />
    </Router>,
  document.getElementById('root')
);

