export default class Utils {
  static setSessionStorageItem = (key: string, value: string) => {
    sessionStorage.setItem(key, value)
  }

  static getSessionStorageItem = (key: string): string | null => {
    return sessionStorage.getItem(key)
  }

  static removeSessionStorageItem = (key: string) => {
    sessionStorage.removeItem(key)
  }

  static isTokenValid = (): boolean => {
    let expDateItem = Utils.getSessionStorageItem("expiration_date")
    if (expDateItem != null) {
      let expirationDate = new Date(expDateItem).valueOf()
      let nowDate = new Date().valueOf()
      return (expirationDate > nowDate)
    }
    else {
      return false
    }
  }
}


