import React, { useEffect, useState } from 'react'
import './ScannedComponent.scss'
import '../utils/string.extensions'
import ComponentGeneral from './componentgeneral/ComponentGeneral';
import ComponentProduct from './componentproduct/ComponentProduct';
import ComponentHeading from './componentheading/ComponentHeading';
import MaintenanceHeader from './maintenanceheader/MaintenanceHeader';
import { ProgressBar } from 'react-bootstrap';
import ComponentComponent from './componentcomponent/ComponentComponent';
import ErrorPopup from '../errorpopup/ErrorPopup';
import { ErrorHandler } from '../utils/ErrorHandler';
import { MaintenanceState } from '../models/IOutputData';
import { SettingsHandler } from '../settings/SettingsHandler';

export default function ScannedComponent(props) {

    const settings = SettingsHandler.Instance
    let timeToNavigateBack = ((settings.getSettings().returnTimeAfterScan) * 1000)

    let [maintenanceHeaderTitle, setMaintenanceHeaderTitle] = useState("")
    let [maintenanceHeaderColor, setMaintenanceHeaderColor] = useState("")
    let [timerProgress, setTimerProgress] = useState(0)

    let [errorMessage, setErrorMessage] = useState("")
    let [showError, setShowError] = useState(false)

    let timeout = null
    let interval = null
    useEffect(() => {
        if (props.location.state === undefined || props.location.state === null) {
            props.history.push("/scan")
            return (null)
        }

        if (props.location.state.scannedcomponent.maintenance.error === null) {
            progressBarFunctionality()
            setMaintenanceHeaderOptions()
        }
        else {
            setErrorMessage(ErrorHandler.getErrorMessage(props.location.state.scannedcomponent.maintenance.error))
            setShowError(true)
        }

        return () => {
            if (timerProgress === 80) {
                clearTimeout(timeout)
            }
            clearInterval(interval)
        };

    })

    const hideErrorPopup = () => {
        setShowError(false)
        returnToScanPage()
    }

    const setMaintenanceHeaderOptions = () => {
        let maintenanceState = props.location.state.scannedcomponent.maintenance.state
        if (maintenanceState != null) {
            switch (maintenanceState) {
                case MaintenanceState.REQUIRED:
                    setMaintenanceHeaderTitle("Wartung notwendig")
                    setMaintenanceHeaderColor("red")
                    break;
                case MaintenanceState.NOT_REQUIRED:
                    setMaintenanceHeaderTitle("Keine Wartung notwendig")
                    setMaintenanceHeaderColor("green")
                    break;
                case MaintenanceState.ALREADY_CREATED:
                    setMaintenanceHeaderTitle("Wartung bereits angelegt")
                    setMaintenanceHeaderColor("#FED700")
                    break;

                case MaintenanceState.NOT_REQUIRED_RECENTLY_INSTALLED:
                    setMaintenanceHeaderTitle("Keine Wartung notwendig - Komponente wurde erst eingebaut")
                    setMaintenanceHeaderColor("green")
                    break;

                default:
                    break;
            }
        }
    }

    const progressBarFunctionality = () => {
        if (timerProgress === 0) {
            window.scrollTo(0, 0)

            timeout = setTimeout(() => {
                returnToScanPage()
            }, timeToNavigateBack);
        }
        interval = setInterval(() => {
            setTimerProgress(timerProgress + (100 / (timeToNavigateBack / 200)))
        }, 200);
    }

    const returnToScanPage = () => {
        props.history.push("/scan")
    }

    return (
        <div className="ScannedComponent">
            {
                (props.location.state === undefined) ? null :
                    <div>
                        <ErrorPopup title={''} message={errorMessage} show={showError} closePopup={hideErrorPopup} />

                        <MaintenanceHeader title={maintenanceHeaderTitle} color={maintenanceHeaderColor} />
                        <ProgressBar animated now={timerProgress} max={80} />
                        <div className="ComponentView">
                            <ComponentHeading
                                title={props.location.state.scannedcomponent.articleDescriptionS}
                                icon={props.location.state.scannedcomponent.icon}
                            />
                            <ComponentGeneral
                                caption={props.location.state.scannedcomponent.caption_general}
                                attributeList={props.location.state.scannedcomponent.generalAttributesList} />

                            <ComponentProduct
                                caption={props.location.state.scannedcomponent.caption_manufacturer_productattributes}
                                attributeList={props.location.state.scannedcomponent.productAttributesList} />
                            <ComponentComponent
                                caption={props.location.state.scannedcomponent.caption_manufacturer_componentattributes}
                                attributeList={props.location.state.scannedcomponent.manufacturerAttributesList} />

                        </div>
                    </div>

            }
        </div>
    );

}
