import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface ErrorPopupOptions {
    title: string,
    message: string,
    show: boolean,
    closePopup: () => void
}

export default function ErrorPopup(props: ErrorPopupOptions) {
    return (
        <>
            <Modal show={props.show} onHide={props.closePopup}>
                {
                    props.title !== "" ? <Modal.Header closeButton>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header> : null
                }
                <Modal.Body>{props.message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.closePopup}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
