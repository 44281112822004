import { MaintenanceCheckerTypes } from "../models/IOutputData";
import { SettingsHandler } from "../settings/SettingsHandler";

export class ErrorHandler {

    static settings = SettingsHandler.Instance

    static getErrorMessage(type: MaintenanceCheckerTypes) {
        switch (type) {
            case MaintenanceCheckerTypes.ATTRIBUTE_MAINTENANCE_CYCLE_AVAILABLE_MULTIPLE_TIMES:
                return `Das individuelle Attribute des technischen Platzes mit dem Namen '${this.settings.getSettings().maintenanceCycleKey}' ist mehrfach vorhanden.`
            case MaintenanceCheckerTypes.ATTRIBUTE_MAINTENANCE_CYCLE_IS_NOT_A_NUMBER:
                return `Das individuelle Attribute des technischen Platzes mit dem Namen '${this.settings.getSettings().maintenanceCycleKey}' muss eine Zahl sein.`
            case MaintenanceCheckerTypes.ATTRIBUTE_MAINTENANCE_CYCLE_NOT_CREATED:
                return `Das individuelle Attribute des technischen Platzes mit dem Namen '${this.settings.getSettings().maintenanceCycleKey}' ist nicht angelegt.`
            case MaintenanceCheckerTypes.COMPONENT_MUTLIPLE_INSTALLED:
                return "Die Komponente ist an mehr als einem technischen Platz verbaut. Das hätte nicht passieren dürfen! Bitte Stellen Sie mit der CONEXO App sicher, dass die Komponente genau an einem technischen Platz verbaut ist."
            case MaintenanceCheckerTypes.COMPONENT_NOT_INSTALLED:
                return "Die Komponente ist an keinem technischen Platz verbaut."
            case MaintenanceCheckerTypes.COULD_NOT_DETERMINE_TAGGEDLOCATION:
                return "Der aktuelle technische Platz konnte nicht ermittelt werden. Stellen Sie sicher, dass die Komponente genau an einem technischen Platz verbaut ist."
            case MaintenanceCheckerTypes.MAINTENANCE_ALREADY_CREATED:
                return "Es ist bereits eine Wartung angelegt."
            case MaintenanceCheckerTypes.MAINTENANCE_REQUIRED:
                return "Wartung notwendig"
            case MaintenanceCheckerTypes.MAINTENANCE_TEMPLATE_AVAILABLE_MULTIPLE_TIMES:
                return `Das individuelle Attribute des technischen Platzes mit dem Namen '${this.settings.getSettings().maintenanceTemplateKey}' ist mehrfach vorhanden.`
            case MaintenanceCheckerTypes.MAINTENANCE_TEMPLATE_NOT_CREATED:
                return `Das individuelle Attribute des technischen Platzes mit dem Namen '${this.settings.getSettings().maintenanceTemplateKey}' ist nicht angelegt.`
            case MaintenanceCheckerTypes.MAINTENANCE_TEMPLATE_NOT_PROPERLY_CARED_FOR:
                return `Bitte stellen Sie sicher, dass das individuelle Attribute des technischen Platzes mit dem Namen '${this.settings.getSettings().maintenanceTemplateKey}' korrekt gepflegt ist. Der Wert muss folgendermaßen aufgebaut sein: MT-Nummer-Name z.B.: 'MT-1-Membranwechsel'.`
            case MaintenanceCheckerTypes.NO_MAINTENANCE_REQUIRED:
                return "Keine Wartung notwendig"
            case MaintenanceCheckerTypes.TAGGEDLOCATION_HAS_NO_LOCATION:
                return "Der Technische Platz muss einem Standort zugewiesen sein."
            default:
                break;
        }
    }

}