import { ISession } from './login/ISession';
import { SettingsHandler } from './settings/SettingsHandler';
import Utils from './utils/Utils';
export default class User {

    private static instance: User = null
    private settingsHandler:SettingsHandler = SettingsHandler.Instance

    private _session: ISession = null
    get session(): ISession {
        this.funLoadSessionFromStorage()
        return this._session
    }

    private constructor() {
        this.funLoadSessionFromStorage()
    }

    private funLoadSessionFromStorage() {
        let retrievedSession = Utils.getSessionStorageItem("session")
        if (retrievedSession != null)
            this._session = JSON.parse(retrievedSession) as ISession
        else {
            this._session = {
                username: "",
                access_token: "",
                expiration_date: "",
                roles: []
            }
        }
    }

    static get Instance(): User {
        if (User.instance == null)
            User.instance = new User()
        return User.instance
    }

    async login(username: string, password: string): Promise<boolean> {
        return fetch('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: username,
                password: password
            })
        })
            .then(async response => {
                let result = await response.json()
                if (response.status >= 400 && response.status < 600) {
                    throw (result)
                }
                return result
            }).then(result => {
                this._session = {
                    access_token: `${result.token_type} ${result.access_token}`,
                    expiration_date: result.expiration_date,
                    username: result.username,
                    roles: result.roles
                }
                this.settingsHandler.initializeSettings(result.settings)
                this.saveSession(this._session)

                return true
            })
            .catch(err => {
                console.log("Error: " + err)
                return false
            })
    }

    canUserChangeSettings() {
        let role = this._session.roles.find(e => {
            return e === "ROLE_PORTAL_ADMIN_APPSETTINGS_VIEW"
        })
        return role !== undefined
    }

    logout() {
        Utils.removeSessionStorageItem("session")
    }

    private saveSession(data: ISession) {
        let sessionString = JSON.stringify(data)
        Utils.setSessionStorageItem("session", sessionString)
    }

    isTokenValid() {
        let expDateItem = this.session.expiration_date
        if (expDateItem !== null && expDateItem !== undefined) {
            let expirationDate = new Date(expDateItem).valueOf()
            let nowDate = new Date().valueOf()
            return (expirationDate > nowDate)
        }
        else {
            return false
        }
    }
}
